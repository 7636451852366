
import React from "react"
import {Row, Col } from 'reactstrap'
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import styled from 'styled-components'

const BreadcrumbWrapper = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: 1fr;
align-content: start;
margin: 0.67em auto -1rem 2rem;

`

const BreadcrumbComponent = ({ pageContext, label }) => {
    let crumbs = pageContext.breadcrumb.crumbs.filter(crumb => crumb.pathname !== "/categoryProcedure");
    return (
        <Row className="justify-content-center">
            <Col className="col-12 col-7 col-md-10 m-auto">
                <BreadcrumbWrapper>
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbSeparator=" > "
                        crumbLabel={label}
                        crumbStyle={{ color: "gray", fontSize: "1em" }}
                        crumbActiveStyle={{ color: "black", fontWeigth:"500" }}
                        crumbWrapperStyle={{ color: "green" }}
                    />
                </BreadcrumbWrapper>
            </Col>
        </Row>
    )
}

export default BreadcrumbComponent