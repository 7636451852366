import React from "react"
import { graphql} from 'gatsby'
import Layout from "../../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb  from "../../components/breadcrumb"
import styled from 'styled-components'
import Image from "gatsby-image"
import useSiteMetadata from '../../hooks/use-site-config'
import { Helmet } from "react-helmet"


export const query = graphql`
query {
  accesCamaraApp: file(relativePath: { eq: "assets/images/app/accesCamaraApp.PNG" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  cipeImag: file(relativePath: { eq: "assets/images/app/cipeImag.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  loginApp: file(relativePath: { eq: "assets/images/app/loginApp.PNG" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
  registroApp: file(relativePath: { eq: "assets/images/app/registroApp.PNG" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
  scanApp: file(relativePath: { eq: "assets/images/app/scanApp.PNG" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }



}
`


const CellImage = styled(Image)`
margin-top:10px

  `
  var styleCol = {
    marginTop: '40px'
  };


export default function SmsApp(props) {
  
  const { siteTitle } = useSiteMetadata()

  const scanApp = props.data.scanApp.childImageSharp.fluid;
  const registroApp = props.data.registroApp.childImageSharp.fluid;
  const loginApp = props.data.loginApp.childImageSharp.fluid;
  const cipeImag = props.data.cipeImag.childImageSharp.fluid;
  const accesCamaraApp = props.data.accesCamaraApp.childImageSharp.fluid;

  return (
    <Layout >
      <Helmet>
          <meta charSet="utf-8" />
           <title>¿Cómo obtengo mi código con CIPE? | {siteTitle}</title>
        </Helmet>
      <Container>
        <h1 className="text-center m-4">¿Cómo obtengo mi código con CIPE?</h1>
        <Row className="justify-content-center">
       
        <Col xs="12" sm="6" md="3" >
        <p>1- Ingresar a la pantalla de la aplicación Portal Puntano desde su celular</p>
        <p>2- Seleccionar el botón "No tengo Contraseña"</p>
        <CellImage fluid={loginApp} style={styleCol}></CellImage>
        </Col>
        <Col xs="12" sm="6" md="3">
        <p>3-  Ingresar los siguientes datos: </p>
            <ul>
            <li>DNI</li>
            <li>Sexo</li>
            <li>Teléfono (con su característica sin 0 y sin 15)</li>
            <li>Fecha de Nacimiento</li>
            </ul>
       
        <CellImage fluid={registroApp}></CellImage>
        </Col>
        <Col xs="12" sm="6" md="3" >
        <p>4- Escanear el código QR de su CIPE</p>
        <CellImage fluid={cipeImag}></CellImage>
        <CellImage fluid={accesCamaraApp}></CellImage>
        </Col>
        
        <Col xs="12" sm="6" md="3">
        <p>5-Seleccionar el botón "Generar Código"</p>
        <p>Se visualizará un mensaje informando que se enviara el código a su celular por mensaje de texto</p>
        <CellImage fluid={scanApp}></CellImage>
        </Col>
        </Row>
      </Container>
      
      <Breadcrumb pageContext={props.pageContext} label="¿Cómo obtengo mi código con CIPE?"/>

    </Layout>
  )
}
